import React from "react";
import "./Style/Clients.scss";
import Adhiparasakthi from "../../Image/clients_images/Adhiparasakthi.png";
import CK from "../../Image/clients_images/CK.png";
import MIT from "../../Image/clients_images/MIT.png";
import Panimalar from "../../Image/clients_images/Panimalar.png";
import PU from "../../Image/clients_images/PU.png";
import SMVC from "../../Image/clients_images/SMVC.png";

export function Clients() {
  return (
    <div className="clients">
      <h1>Our Clients</h1>
      <p>
        Various Educational Institutes and Universities prefer our pioneering
        training methods for their students.
      </p>
      <br />
      <div className="row">
        <img src={MIT} alt="" />
        <img src={PU} alt="" />
        <img src={Panimalar} alt="" />
        <img src={Adhiparasakthi} alt="" />
        <img src={SMVC} alt="" />
        <img src={CK} alt="" id="ckClg" />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import "./Style/WhatsNewAtOcean.scss";
import Whats_New from "../../Image/whats_new.png";
import Job_Portal from "../../Image/job_portal.png";
import AOS from "aos";
import "aos/dist/aos.css";

export function WhatsNewAtOcean() {
  useEffect(() => {
    AOS.init({
      // duration: 1000,
    });
  }, []);

  return (
    <div className="whatsNewAtOcean">
      <h1
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-duration="500"
        // data-aos-easing="ease-in-sine"
      >
        What's New at Ocean Academy
      </h1>
      <p
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-duration="500"
        // data-aos-easing="ease-in-sine"
      >
        We are proud to release our most innovative learning platform to learn
        cutting-edge technologies. Here's what's coming up next for Ocean
        Academy Live Members.
      </p>
      
      
      
      <div className="row">
        <div className="col">
          <h1
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            Ocean Academy Launches its own Job Portal for our OA-Students.
          </h1>
          <p
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            {/* <b>Welcome to OA Job Portal by Ocean Academy!</b><br></br>
Your IT career gateway! Connect with top tech companies effortlessly.<br/>

<b>For Candidates:</b><br/>

<b>Explore Opportunities:</b> Discover jobs that match your skills and goals.<br/>
<b>Boost Your Career:</b> Exclusive listings and personalized recommendations.<br/>
<b>For Recruiters:</b><br/>

<b>Find Talent:</b> Access a pool of skilled IT professionals.<br/>
<b>Hire Smart:</b> Streamline recruitment with top-notch candidates.<br/>
Join OA Job Portal today and connect with the best in tech recruitment. Your dream job or ideal hire is just a click away! */}
Welcome to the OA Job Portal by Ocean Academy, your pathway to success in the IT industry! Our platform connects skilled IT professionals with leading tech companies, simplifying the job search and recruitment process.<br/>

<b>For candidates:</b> Explore exclusive job listings tailored to your skills and career goals, whether you're an experienced professional or starting your journey.<br/>

<b>For recruiters:</b> Access a diverse pool of talented candidates to find the perfect fit for your team effortlessly.<br/>

Join the OA Job Portal today and connect with endless opportunities in tech recruitment-your dream job or ideal candidate is just a click away!<br/>
          </p>
        </div>
        <div id="portal"
          data-aos="zoom-in-up"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="col"
        >
          <img src={Job_Portal} alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            Ocean Academy Launches its own LMS for our OA-Live Learners.
          </h1>
          <p
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-duration="500"
            // data-aos-easing="ease-in-sine"
          >
            Ocean Academy Launches its own LMS for our OA-Live Learners. OA has
            launched its own CMS for our learners to take the online class and
            enroll in the new online batch effortlessly. This app will be more
            helpful for online learners to get the class schedule details.
          </p>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          className="col"
        >
          <img src={Whats_New} alt="" />
        </div>
      </div>
    </div>
    
  );
}
